import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import { useQuery } from '@apollo/client'

import styles from './style.module.scss'
import rankIcon from '@assets/images/icon/rank-icon-dark.svg'
import SeriesKeyStatsPlayer from '@shared/components/seriesKeyStatPlayer'
import { KEY_STATS } from '@graphql/series/stats.query'
import { CustomLink } from '@pages/advertise-with-us-old'

const MyImage = dynamic(() => import('@shared/components/myImage'))
// const CustomLink = dynamic(() => import('@shared/components/customLink'))

function SeriesKeyStats({
  data,
  iSeriesId = '63f052b9d5e097df610db62d',
  mostRuns,
  mostWickets,
  mostFifties,
  mostCenturies,
  mostSixes,
  title,
  CapSectionTitle,
  statsURL
}) {
  const { t } = useTranslation()
  const [capHolder, setCapHolder] = useState(
    data?.mostRuns?.length && data?.mostWickets?.length ? [...data?.mostRuns, ...data?.mostWickets] : []
  )

  const { data: orangeCapData } = useQuery(KEY_STATS, {
    skip: data?.mostRuns?.length > 0,
    variables: { input: { iSeriesId, _id: mostRuns?.id || '62302fc0358523ee1d264d15', nLimit: 1 } }
  })
  const { data: purpleCapData } = useQuery(KEY_STATS, {
    skip: data?.mostWickets?.length > 0,
    variables: { input: { iSeriesId, _id: mostWickets?.id || '6230303b358523ee1d269831', nLimit: 1 } }
  })
  const { data: mostFiftyData } = useQuery(KEY_STATS, {
    skip: data?.mostFifties?.length > 0,
    variables: { input: { iSeriesId, _id: mostFifties?.id || '62303026358523ee1d2690a7', nLimit: 1 } }
  })
  const { data: mostCenturiesData } = useQuery(KEY_STATS, {
    skip: data?.mostCenturies?.length > 0,
    variables: { input: { iSeriesId, _id: mostCenturies?.id, nLimit: 1 } }
  })
  const { data: MostSixesData } = useQuery(KEY_STATS, {
    skip: data?.mostSixes?.length > 0,
    variables: { input: { iSeriesId, _id: mostSixes?.id || '6230302c358523ee1d2692ce', nLimit: 1 } }
  })

  useEffect(() => {
    if (orangeCapData && purpleCapData) {
      setCapHolder([...orangeCapData?.fetchSeriesStats, ...purpleCapData?.fetchSeriesStats])
    }
  }, [orangeCapData, purpleCapData])

  const fifties = data?.mostFifties || mostFiftyData?.fetchSeriesStats || []
  const centuries = data?.mostCenturies || mostCenturiesData?.fetchSeriesStats || []
  const sixes = data?.mostSixes || MostSixesData?.fetchSeriesStats || []
  // return (
  //   <>
  //     <section className={`${styles.seriesStats} common-box widget pt-3 pb-2 px-3 position-relative overflow-hidden`}>
  //       <div className="widget-title d-flex align-items-center justify-content-between">
  //         <h3 className="small-head d-flex align-items-center text-uppercase mb-0">
  //           <span className={`${styles.icon} icon me-1`}>
  //             <MyImage src={rankIcon} alt="winner" width="24" height="24" />
  //           </span>
  //           {'World Cup Stats' || 'IPL 2020'}
  //         </h3>
  //         <div>
  //           <CustomLink href={statsURL || '/ipl-points-table/?ref=hw'} className="theme-btn outline-btn xsmall-btn" prefetch={false}>
  //             {t('common:ViewAll')}
  //           </CustomLink>
  //         </div>
  //       </div>
  //       <div className="d-flex d-md-block mx-n1 mx-md-0 overflow-auto">
  //         {capHolder?.length > 0 && (
  //           <>
  //             {/* <h5 className="big-text mb-1 ms-2 mt-3">{CapSectionTitle || t('common:CapHolders')}</h5> */}
  //             {capHolder?.map((data, index) => {
  //               return (
  //                 <div
  //                   key={index}
  //                   className={`${styles.searchCard} bg-transparent position-relative overflow-hidden p-0 flex-shrink-0 me-2`}
  //                 >
  //                   <SeriesKeyStatsPlayer
  //                     title={t('common:Most')}
  //                     key={index}
  //                     index={index}
  //                     section="CapHolders"
  //                     iSeriesId={iSeriesId}
  //                     data={data}
  //                     statsData={mostRuns}
  //                     secondStatsData={mostWickets}
  //                   />
  //                 </div>
  //               )
  //             })}
  //           </>
  //         )}
  //         {fifties?.length > 0 && (
  //           <>
  //             <div className={`${styles.searchCard} bg-transparent position-relative overflow-hidden p-0 flex-shrink-0 me-2`}>
  //               <SeriesKeyStatsPlayer title={t('common:Most')} section="MostFifties" data={fifties[0] || {}} statsData={mostFifties} />
  //             </div>
  //           </>
  //         )}
  //         {/* {centuries?.length > 0 && (
  //           <>
  //             <div className={`${styles.searchCard} bg-transparent position-relative overflow-hidden p-0 flex-shrink-0 me-2`}>
  //               <SeriesKeyStatsPlayer
  //                 title={t('common:Most')}
  //                 section="MostCenturies"
  //                 data={centuries[0] || {}}
  //                 statsData={mostCenturies}
  //               />
  //             </div>
  //           </>
  //         )} */}
  //         {sixes?.length > 0 && (
  //           <>
  //             {/* <h5 className="big-text mb-1 ms-2 mt-3">{t('common:MostSixes')}</h5> */}
  //             <div className={`${styles.searchCard} bg-transparent position-relative overflow-hidden p-0 flex-shrink-0 me-2`}>
  //               <SeriesKeyStatsPlayer title={t('common:Most')} section="MostSixes" data={sixes[0] || {}} statsData={mostSixes} />
  //             </div>
  //           </>
  //         )}
  //       </div>
  //     </section>
  //   </>
  // )

  return (
    <section className="common-box widget px-2 pb-2 pt-3">
      <div className="widget-title d-flex align-items-center justify-content-between">
        <h3 className="small-head d-flex align-items-center text-uppercase mb-0">
          <span className={`${styles.icon} icon me-1`}>
            <MyImage src={rankIcon} alt="winner" width="24" height="24" layout="responsive" />
          </span>
          IPL 2025 Key Stats
        </h3>
        <div>
          <CustomLink href={statsURL || '/ipl-points-table/?ref=hw'} className="theme-btn outline-btn xsmall-btn" prefetch={false}>
            {t('common:ViewAll')}
          </CustomLink>
        </div>
      </div>
      <h5 className="big-text mb-1 ms-2 mt-3">{t('common:CapHolders')}</h5>
      <div className={`${styles.searchCard} common-box`}>
        {capHolder?.map((data, index) => {
          return <SeriesKeyStatsPlayer key={index} index={index} section="CapHolders" iSeriesId={iSeriesId} data={data} />
        })}
      </div>
      <h5 className="big-text mb-1 ms-2 mt-3">Most 100s</h5>
      <div className={`${styles.searchCard} common-box`}>
        <SeriesKeyStatsPlayer section="MostCenturies" data={centuries?.[0]} />
      </div>
      <h5 className="big-text mb-1 ms-2 mt-3">{t('common:MostFifties')}</h5>
      <div className={`${styles.searchCard} common-box`}>
        <SeriesKeyStatsPlayer section="MostFifties" data={fifties?.[0]} />
      </div>
      <h5 className="big-text mb-1 ms-2 mt-3">{t('common:MostSixes')}</h5>
      <div className={`${styles.searchCard} common-box`}>
        <SeriesKeyStatsPlayer section="MostSixes" data={sixes?.[0]} />
      </div>
    </section>
  )
}
SeriesKeyStats.propTypes = {
  iSeriesId: PropTypes.string.isRequired,
  data: PropTypes.object,
  mostSixes: PropTypes.object,
  mostRuns: PropTypes.object,
  mostWickets: PropTypes.object,
  mostFifties: PropTypes.object,
  mostCenturies: PropTypes.object,
  title: PropTypes.string,
  CapSectionTitle: PropTypes.string,
  statsURL: PropTypes.string
}

export default SeriesKeyStats
