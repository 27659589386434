import React from 'react'
import dynamic from 'next/dynamic'
import styles from './style.module.scss'
import PropTypes from 'prop-types'

import OrangeCap from '@assets/images/placeholder/ipl-caps/orange.png'
import PurpleCap from '@assets/images/placeholder/ipl-caps/purple.png'
import Skeleton from '../skeleton'
import { useRouter } from 'next/router'

const MyImage = dynamic(() => import('@shared/components/myImage'))
const PlayerImg = dynamic(() => import('@shared/components/playerImg'))

function SeriesKeyStatsPlayer({ data, section, index, iSeriesId, statsData, secondStatsData, title }) {
  const router = useRouter()
  const isOrangeCap = index === 0
  const sData = getValue()

  function getValue() {
    if (section === 'CapHolders') {
      if (isOrangeCap) {
        return { value: data?.nRuns, label: 'Runs', link: statsData?.URL || '/ipl-orange-cap/' }
      } else {
        return { value: data?.nWickets, label: 'Wickets', link: secondStatsData?.URL || '/ipl-purple-cap/' }
      }
    } else if (section === 'MostFifties') {
      return { value: data?.nRun50, label: 'Fifties', link: statsData?.URL || '/t20/ipl-indian-premier-league/stats/batting-most-run50/' }
    } else if (section === 'MostCenturies') {
      return { value: data?.nRun100, label: '100s', link: statsData?.URL }
    } else if (section === 'MostSixes') {
      return { value: data?.nRun6, label: 'Sixes', link: statsData?.URL || '/t20/ipl-indian-premier-league/stats/batting-most-sixes/' }
    }
  }

  function redirectTo(url) {
    router.push(sData.link)
  }

  return (
    <>
      {data ? <div onClick={redirectTo} className={`${styles.statPlayer} pt-2 pe-2 d-flex align-items-center justify-content-between position-relative overflow-hidden`}>
        <div className="d-flex align-items-center">
          <div className={`${styles.playerImage} d-flex`}>
            {(iSeriesId === '67b309a8934a1036b69aafb4' && section === 'CapHolders') && (
              <div className={`${styles.icon} me-2 flex-shrink-0`}>
                <MyImage
                  src={isOrangeCap ? OrangeCap : PurpleCap}
                  // blurDataURL={isOrangeCap ? OrangeCap : PurpleCap}
                  alt={data?.oPlayer?.sFullName}
                  placeholder="blur"
                  width="20"
                  height="20"
                />
              </div>
            )}
            <div className={`${styles.icon} me-2 flex-shrink-0`}>
              <PlayerImg
                head={data?.oPlayer?.oImg}
                jersey={data?.oTeam?.oJersey}
              />
            </div>
          </div>
          <div>
            {/* <h3 className='small-head mb-1'>{title} {sData.label}</h3> */}
            <h4 className={`${styles.playerName} font-semi small-head mb-0`}>
              {data?.oPlayer?.sFullName || data?.oPlayer?.sTitle || data?.oPlayer?.sFirstName}
            </h4>
            <p className="xsmall-text secondary-text text-uppercase mb-0 mt-1">
              {data?.oTeam?.sAbbr}
            </p>
          </div>
        </div>
        <div className="d-flex flex-column align-items-end justify-content-center">
          <h3 className={`${styles.value} font-bold mb-2 small-head`}>{sData.value}</h3>
          <p className="m-0">{sData.label}</p>
        </div>
      </div> : <div className={`${styles.statPlayer} d-flex justify-content-between`}>
        <div className="d-flex align-item-center justify-content-start mt-1 mb-1">
          {section === 'CapHolders' && <Skeleton width={'44px'} height={'44px'} className="me-2" radius={'50%'} />}
          <Skeleton width={'44px'} height={'44px'} className="me-2" radius={'50%'} />
          <div className="d-flex flex-column align-item-center justify-content-center">
            <Skeleton width={'60px'} height={'13px'} className="mb-1" />
            <Skeleton width={'36px'} height={'13px'} />
          </div>
        </div>
        <div className="d-flex flex-column align-items-end justify-content-center">
          <Skeleton width={'36px'} height={'13px'} className="mb-1" />
          <Skeleton width={'60px'} height={'13px'} />
        </div>
      </div>
      }
    </>
  )
}

SeriesKeyStatsPlayer.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
  iSeriesId: PropTypes.string,
  title: PropTypes.string,
  statsData: PropTypes.object,
  secondStatsData: PropTypes.object
}
export default SeriesKeyStatsPlayer
